import { Fragment, ReactNode, JSX } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export enum ModalSizes {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl',
}

export interface ModalProps {
  title?: ReactNode;
  isOpen: boolean;
  closeModal: () => void;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  children?: ReactNode;
  maxWidth?: ModalSizes;
}

export default function Modal({
  title,
  isOpen,
  closeModal,
  initialFocus,
  children,
  maxWidth = ModalSizes.LG,
}: ModalProps): JSX.Element {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog initialFocus={initialFocus} as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative w-full transform overflow-hidden rounded-lg border-4  border-gray-200 bg-gray-50 dark:border-gray-800 dark:bg-gray-900 p-4 text-left shadow-xl transition-all sm:my-8 sm:pb-6 md:max-w-${maxWidth}`}
                id="popupParent"
              >
                <div className="mb-3 flex justify-between">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                    {title}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="h-fit rounded-md bg-transparent text-gray-200 hover:text-red-600 focus:text-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">{children ? <div className="grow">{children}</div> : null}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
